.htmltext {
	composes: g-type-body-small from global;
	margin-top: var(--hdsplus-spacing-04);
	margin-bottom: var(--hdsplus-spacing-07);
	color: var(--token-color-foreground-faint);

	& a {
		color: var(--token-color-foreground-action);
		text-decoration: underline;

		&:focus-visible {
			text-decoration: none;
			border-radius: 2px;
			outline: 2px solid var(--token-color-focus-action-internal);
			outline-offset: 1px;
		}
	}
}
